"use strict";

import { setTimeout } from "core-js";

const loadDataTable = function (id, exportTitle) {
  loadDataTableWithoutExport(id, exportTitle, true);
}

const loadDataTableWithoutExport = function (id, exportTitle, exportFlag) {
  var exportOption;
  if (exportFlag) {
    exportOption = [{ extend: "copy" },
    { extend: "csv" },
    { extend: "excel", title: exportTitle },
    { extend: "pdf", title: exportTitle },

    {
      extend: "print",
      customize: function (win) {
        $(win.document.body).addClass("white-bg");
        $(win.document.body).css("font-size", "10px");
        $(win.document.body)
          .find("table")
          .addClass("compact")
          .css("font-size", "inherit");
      },
    },]
  } else {
    exportOption = []
  }

  const selectedRows = new Set(); // Use a Set to store selected row IDs

  setTimeout(() => {
    var table = $("." + id).DataTable({
      columnDefs: [
        { orderable: false, targets: 0 } // Disable sorting on the first column (checkbox column)
    ],
      pageLength: 10,
      responsive: true,
      destroy: true,
      dom: '<"html5buttons"B>lTfgitp',
      buttons: exportOption,
    });
  
    // Select All Checkbox
    $('#selectAll').on('click', function () {
      const isChecked = $(this).is(':checked');
      $('.rowCheckbox', table.rows().nodes()).prop('checked', isChecked); // Check/uncheck all visible checkboxes
  });

  var checkedCount = $('.rowCheckbox:checked');
  if(checkedCount && checkedCount.length > 0) {
    $('#selectAll').prop('checked', true);
  }else {
    $('#selectAll').prop('checked', false);
  }
  }, 1200);
}


const loadDataTableFooterSearch = function (id, exportTitle) {
  var exportOption;

  exportOption = [{ extend: "copy" },
  { extend: "csv" },
  { extend: "excel", title: exportTitle },
  { extend: "pdf", title: exportTitle },

  {
    extend: "print",
    customize: function (win) {
      $(win.document.body).addClass("white-bg");
      $(win.document.body).css("font-size", "10px");
      $(win.document.body)
        .find("table")
        .addClass("compact")
        .css("font-size", "inherit");
    },
  },]

  $("." + id + ' tfoot th').each(function () {
    var title = $(this).text();
    $(this).html('<input type="text" class="form-control" placeholder="Search ' + title + '" />');
  });

  setTimeout(() => {
    var table = $("." + id).DataTable({
      pageLength: 10,
      responsive: true,
      destroy: true,
      dom: '<"html5buttons"B>lTfgitp',
      buttons: exportOption,
      initComplete: function () {
        this.api().columns().every(function () {
          var that = this;
          $('input', this.footer()).on('keyup change clear', function () {
            if (that.search() !== this.value) {
              that
                .search(this.value)
                .draw();
            }
          });
        });
      }
    });

  }, 1200);
}


const loadDataTableWithRowCheckbox = function (id, exportTitle, exportFlag) {
  var exportOption;
  var columnDefsData;
  var selectData;
  var orderData;
  if (exportFlag) {
    exportOption = [{ extend: "copy" },
    { extend: "csv" },
    { extend: "excel", title: exportTitle },
    { extend: "pdf", title: exportTitle },

    {
      extend: "print",
      customize: function (win) {
        $(win.document.body).addClass("white-bg");
        $(win.document.body).css("font-size", "10px");
        $(win.document.body)
          .find("table")
          .addClass("compact")
          .css("font-size", "inherit");
      },
    },]
  } else {
    exportOption = []
    columnDefsData = [
      {
        'targets': 0,
        'checkboxes': {
          'selectRow': true
        }
      }
    ];
    selectData = {
      'style': 'multi',
      selector: 'td:first-child'
    };
    orderData = [[1, 'asc']];
  }

  setTimeout(() => {
    var table = $("." + id).DataTable({
      pageLength: 10,
      responsive: true,
      destroy: true,
      dom: '<"html5buttons"B>lTfgitp',
      buttons: exportOption,
      columnDefs: columnDefsData,
      select: selectData,
      order: orderData,
      select: true,
    });
  }, 1000);
}

const reLoadDataTable = function (id) {
  $('.' + id).dataTable({
    "bDestroy": true
  }).fnDestroy();
}


export default {
  loadDataTable,
  reLoadDataTable,
  loadDataTableWithoutExport,
  loadDataTableWithRowCheckbox,
  loadDataTableFooterSearch
}